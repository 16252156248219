<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg"
        v-if="selling.length > 0"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="mt-5">
              <div>
                <!-- <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  :src="img_cover"
                  alt=""
                  v-if="img_cover"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  src="https://promptdue.com/img/home.png"
                  alt=""
                  v-else
                /> -->
              </div>

              <div class="">
                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-20 w-20 sm:w-32 sm:h-32 border-2 rounded-full border-red-500"
                  :src="img_profile"
                  alt=""
                  v-if="img_profile"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-16 w-16 lg:w-24 lg:h-24 border-2 rounded-full border-red-500"
                  src="https://media.discordapp.net/attachments/1080058777971212370/1083317410674769940/PromptDue_Logo_64x64px_02.png?width=128&height=128"
                  alt=""
                  v-else
                />
              </div>

              <div class="">
                <div>
                  <div class="mt-3 flex">
                    <h3
                      class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                      v-if="getUser.name != '' && getUser.name"
                    >
                      @{{ getUser.name }}
                    </h3>
                    <h3
                      class="text-sm lg:text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                      v-else
                    >
                      @ {{ getUser.address }}
                    </h3>
                  </div>

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-4"
                  >
                    {{ getUser.description }}
                  </h3>

                  <div v-if="facebook || telegram || instagram">
                    <h3 class="text-lg font-bold text-white mt-10">Social</h3>

                    <div class="text-gray-200 mt-2 flex">
                      <a
                        :href="facebook"
                        v-if="facebook && facebook != ''"
                        class="text-3xl font-bold"
                      >
                        <i class="fab fa-facebook-square"></i>
                      </a>
                      <a
                        :href="telegram"
                        v-if="telegram && telegram != ''"
                        class="text-3xl font-bold ml-3"
                      >
                        <i class="fab fa-telegram"></i>
                      </a>
                      <a
                        :href="instagram"
                        v-if="instagram && instagram != ''"
                        class="text-3xl font-bold ml-3"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>

                    <hr
                      class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
            <router-link
              :to="'/editaccount/' + getUser.address"
              class="w-full flex justify-end"
            >
              <h3 class="text-sm font-bold text-gray-200 underline">
                Edit Profile ->
              </h3>
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="flex w-full p-3 pb-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg"
      >
        <div class="py-4 space-y-6 md:py-8 lg:py-2 w-full text-gray-300">
          <div
            class="w-full p-px bg-gradient-to-r from-blue-700 via-gray-300 to-green-500 rounded-3xl shadow-xl"
          >
            <div
              class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl bg-gray-900 bg-opacity-75"
            >
              <div class="flex flex-col justify-center space-y-3">
                <div class="bg-gray-800 py-2 px-3 rounded">
                  <div class="text-base font-medium">
                    <i class="fad fa-wallet mr-2 z-0"></i>
                    <span
                      class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-400"
                    >
                      {{ getUser.address.slice(0, 12) }}...{{
                        getUser.address.slice(-10)
                      }}
                    </span>
                  </div>
                </div>
                <div class="flex items-center space-x-3 gap-2">
                  <a
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 space-x-1 flex items-center justify-center bg-clip-text text-transparent bg-gradient-to-r from-green-200 to-blue-200"
                    v-bind:href="`https://${EXPLORER}/address/${getUser.address}`"
                    target="_blank"
                    ><i class="fad fa-link mr-2 text-blue-200"></i> View on
                    explorer</a
                  >

                  <div
                    class="flex items-center flex-shrink-0 space-x-1 no-underline cursor-pointer whitespace-nowrap hover:no-underline focus:no-underline active:no-underline text-blue opacity-80 hover:opacity-100 focus:opacity-100 bg-clip-text text-transparent bg-gradient-to-r from-green-200 to-blue-200"
                  >
                    <input
                      type="hidden"
                      id="text-address"
                      :value="getUser.address"
                    />
                    <a v-on:click="copyAddress()" class="text-sm font-medium"
                      ><i class="fad fa-copy text-blue-200"></i>
                      {{ copymsg }}</a
                    >
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <span class="text-gray-300">Value(USD)</span>
                <h3 class="text-2xl text-shadow-white">
                  ≈ $ {{ usd(total_usd) }}
                </h3>

                <div class="flex flex-col sm:flex-row gap-2 mt-2">
                  <router-link
                    v-bind:to="'/transactions/' + getUser.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-200"
                    ><i class="fad fa-copy mr-1 text-blue-200"></i>
                    Transactions</router-link
                  >

                  <router-link
                    v-bind:to="'/deposit/' + getUser.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-200 mr-3"
                    ><i class="fad fa-clipboard-list mr-1"></i> Deposit
                    history</router-link
                  >

                  <router-link
                    v-bind:to="'/withdraw/' + getUser.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-200 mr-3"
                    ><i class="fad fa-clipboard-list mr-1"></i> Withdrawal
                    history</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="text-gray-100">
            {{ price }}
          </div> -->

          <div class="p-1">
            <div v-if="getBalance && getCurrency">
              <div v-for="b in getBalance" v-bind:key="b.id" class="">
                <router-link
                  v-bind:to="'/balance/' + b.coin"
                  class="w-full"
                  v-if="b.balance > 0 || hide.smallasset"
                >
                  <div
                    class="flex border-l border-t border-b border-r boder-pink-600 border-opacity-75 shadow-md py-1 px-3 bg-gray-900 rounded-lg mb-2 items-center"
                  >
                    <div class="flex-none">
                      <b v-if="getCurrency[b.coin].img_pair == true">
                        <img
                          class="h-5 inline mr-2"
                          :src="getCurrency[b.coin].logo"
                        />

                        <img
                          class="h-5 inline mr-2"
                          :src="getCurrency[b.coin].logo2"
                        />
                      </b>

                      <b v-else>
                        <img
                          class="h-5 inline mr-2"
                          :src="getCurrency[b.coin].logo"
                        />
                      </b>

                      <span class="mr-2">{{ b.coin }}</span>
                      <span class="text-xs">{{
                        getCurrency[b.coin].name
                      }}</span>
                      <br />
                      <span class="text-xs" v-if="getCurrency[b.coin].price">
                        <span
                          class="font-bold text-green-400"
                          v-for="c in price"
                          :key="c"
                        >
                          <b v-if="c.name == b.coin">${{ c.value }}</b>
                        </span>

                        <span class="ml-1" v-if="b.coin == 'BNB'">
                          Swap auto to USDT
                        </span>

                        <!-- <span class="font-bold"
                          >${{ getCurrency[b.coin].price.last_price }}</span
                        > -->
                        <!-- <span
                          class="text-green-500"
                          v-if="getCurrency[b.coin].price.change / 1 >= 0"
                        >
                          +{{ getCurrency[b.coin].price.change }}%
                        </span>
                        <span class="text-red-500" v-else>
                          {{ getCurrency[b.coin].price.change }}%
                        </span> -->
                      </span>
                      <span class="text-xs" v-else
                        ><span class="font-bold">$0</span>
                        <span class="text-green-500"> +0% </span>
                      </span>
                    </div>

                    <div class="flex-grow text-right cursor-pointer">
                      <span class="">{{ amount(b.balance) }}</span>
                      <br />

                      <span class="text-gray-500 text-xs">
                        <div
                          v-for="n in tokenBalance"
                          v-bind:key="n.name"
                          class=""
                        >
                          <div v-if="b.coin == n.name">
                            Deposit Available:
                            {{ parseFloat(n.balance).toFixed(4) }}
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg"
        v-if="purchasd.length > 0"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
            <div class="text-gray-200 mt-10" v-if="purchasd.length > 0">
              <h3
                class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
              >
                Purchased
              </h3>

              <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

              <div class="grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-3">
                <div
                  class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                  style="margin-top: 10px"
                  v-for="s in purchasd"
                  :key="s"
                >
                  <div
                    class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                  >
                    <div class="text-sm border-none rounded bg-gray-900">
                      <a class="" :href="'/prompt/' + s.prompt._id">
                        <img
                          :src="s.prompt.img_1"
                          alt="Image 1"
                          class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-44 md:h-48 lg:h-60 w-full"
                        />

                        <img
                          :src="s.prompt.img_2"
                          style="height: 15.3rem"
                          alt="Image 2"
                          class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-44 md:h-48 lg:h-60 w-full"
                        />

                        <!-- <img :src="s.prompt.img_1" /> -->

                        <div class="flex -mt-7 ml-2">
                          <div class="flex-none">
                            <div
                              class="flex border rounded bg-blue-900 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-200">
                                <i
                                  class="fa fa-check-square text-green-500"
                                ></i>
                                {{ s.prompt.prompt_type }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                          >
                            <h3 class="text-sm font-bold">
                              <i class="fas fa-eye"></i>

                              {{ s.prompt.view }}

                              <i class="fas fa-heart ml-2"></i>

                              {{ s.prompt.favorite }}
                            </h3>
                          </div>
                        </div>

                        <div class="flex"></div>

                        <div class="flex bg-black pt-1">
                          <div class="flex-none">
                            <h4 class="text-xs p-2 mt-1 text-gray-200">
                              ⛵ {{ s.prompt.title.slice(0, 12) }} ...
                            </h4>
                          </div>

                          <div class="flex-grow p-2 text-right text-gray-200">
                            {{ s.prompt.price }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg mb-10 mt-10"
        v-if="selling.length > 0"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
            <div class="text-gray-200">
              <h3
                class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
              >
                Selling
              </h3>

              <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

              <div class="grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-4">
                <div
                  class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                  style="margin-top: 10px"
                  v-for="s in selling"
                  :key="s"
                >
                  <div
                    class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                  >
                    <div class="text-sm border-none rounded bg-gray-900">
                      <a class="">
                        <img
                          :src="s.img_1"
                          alt="Image 1"
                          class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-44 md:h-48 lg:h-60 w-full"
                        />

                        <div
                          class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-full w-full rounded-xl"
                        >
                          <div
                            class="flex flex-wrap content-end justify-center h-full w-full border-2 border-red-500 rounded-xl border-0.5"
                          >
                            <div
                              class="bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 w-full flex justify-center pt-4 pb-4"
                            >
                              <div class="text-center">
                                <a
                                  :href="'/prompt/' + s._id"
                                  class="text-lg text-neutral-600 text-white hover:text-gray-400"
                                >
                                  View Detail
                                </a>

                                <div
                                  class="flex justify-center mt-5 cursor-pointer"
                                  @click="deletePromptByUser(s._id)"
                                >
                                  <span
                                    class="h-14 w-14 flex justify-center items-center"
                                  >
                                    <span
                                      class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-blue-500 hover:bg-red-400 opacity-75"
                                    ></span>
                                    <div
                                      class="relative inline-flex rounded-full h-8 w-8 bg-purple-600 hover:bg-red-500 flex justify-center items-center text-3xl text-gray-200"
                                    >
                                      X
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="flex -mt-7 ml-2"
                          v-if="s.audit == 'approve'"
                        >
                          <div class="flex-none">
                            <div
                              class="flex border rounded bg-blue-900 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-200">
                                <i
                                  class="fa fa-check-square text-green-500"
                                ></i>
                                {{ s.prompt_type }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                          >
                            <h3
                              class="text-sm font-bold"
                              v-if="s.audit == 'approve'"
                            >
                              <i class="fas fa-eye"></i>

                              {{ s.view }}

                              <i class="fas fa-heart ml-2"></i>

                              {{ s.favorite }}
                            </h3>
                          </div>
                        </div>

                        <div
                          class="flex -mt-7 ml-1 mr-1"
                          v-else-if="s.audit == 'wait approve'"
                        >
                          <div class="">
                            <div
                              class="flex border rounded bg-yellow-500 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-200">
                                wait Approve by admin
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="flex -mt-7 ml-1 mr-1"
                          v-else-if="s.audit == 'reject'"
                        >
                          <div class="">
                            <div
                              class="flex border rounded bg-red-500 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-200">
                                Reject by admin
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bg-black">
                          <h3
                            v-if="s.rejectDetail != '' && s.rejectDetail"
                            class="text-xs p-2 text-center"
                          >
                            {{ s.rejectDetail }}
                          </h3>
                          <div class="flex">
                            <div class="flex-none">
                              <h4 class="text-xs p-2 mt-1 text-gray-200">
                                ⛵ {{ s.title.slice(0, 10) }} ...
                              </h4>
                            </div>

                            <div class="flex-grow p-2 text-right text-gray-200">
                              {{ s.price }}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import func from "@/widgets/func";
import { mapActions, mapGetters } from "vuex";
import { EXPLORER, CHAIN } from "../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

import Prompt from "@/api/prompt";

import Currency from "@/api/currency";

import Web3 from "web3";

import Swal from "sweetalert2";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  components: {
    ConnectWallet,
    Carousel,
    Pagination,
    Navigation,
    Slide,
  },
  data() {
    return {
      EXPLORER: EXPLORER,
      copymsg: "Copy Address",
      total_usd: 0,
      hide: JSON.parse(localStorage.getItem("HideAsset")) || {
        status: true,
        smallasset: true,
      },
      tokenBalance: [],
      chain: CHAIN.toLowerCase(),
      selling: [],
      purchasd: [],
      img_3: "",
      img_profile: "",
      img_cover: "",
      name: "name",
      description: "",
      facebook: "",
      telegram: "",
      instagram: "",
      price: [],
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    ...mapActions(["fetchAssets", "fetchBalance", "fetchMarket"]),

    hideDelisted() {
      this.hide.status = !this.hide.status;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    hideSmall() {
      this.hide.smallasset = !this.hide.smallasset;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    amount(a) {
      return func.fixed8(a);
    },
    usd(a) {
      return func.fixed2(a);
    },
    caltotal() {
      var total = 0;

      for (let [coin, value] of Object.entries(this.getBalance)) {
        if (this.getCurrency[coin].price) {
          total =
            total + value.balance * this.getCurrency[coin].price.last_price;
        }
      }
      this.total_usd = total;
    },
    copyAddress() {
      let textToCopy = document.querySelector("#text-address");
      textToCopy.setAttribute("type", "text"); // hidden
      textToCopy.select();

      try {
        document.execCommand("copy");
        this.copymsg = "Copied";
        setTimeout(() => {
          this.copymsg = "Copy Address";
        }, 1000);
      } catch (err) {
        this.copymsg = "Oops, unable to copy";
      }

      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      var accounts = await ethereum.request({ method: "eth_requestAccounts" });

      for (let [coin, value] of Object.entries(this.getBalance)) {
        if (coin == "BNB" || coin == "ETH" || coin == "MATIC") {
          const ether_raw = await web3.eth.getBalance(accounts[0]);
          var amount_coin = (ether_raw / Math.pow(10, 18)).toFixed(8);
          this.tokenBalance.push({
            name: coin,
            balance: amount_coin,
          });
        } else {
          var contract_address =
            this.getCurrency[coin].address[this.chain].address;
          const tokenInst = new web3.eth.Contract(tokenABI, contract_address);
          // console.log(coin, tokenInst);
          const token_raw = await tokenInst.methods
            .balanceOf(accounts[0])
            .call();
          var amount_coin = (token_raw / Math.pow(10, 18)).toFixed(8);
          this.tokenBalance.push({
            name: coin,
            balance: amount_coin,
          });
        }
      }
    },
    async sellingPrompt() {
      await Prompt.selling({
        address: this.getUser.address,
      })
        .then((res) => {
          this.selling = res.data.prompt;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async BuyerPrompt() {
      await Prompt.buyer({
        address: this.getUser.address,
      })
        .then((res) => {
          this.purchasd = res.data.prompt;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData() {
      if (this.getUser.img_profile) {
        this.img_profile = this.getUser.img_profile;
      }
      if (this.getUser.img_cover) {
        this.img_cover = this.getUser.img_cover;
      }

      if (this.getUser.name) {
        this.name = this.getUser.name;
      } else {
        this.name = this.getUser.address;
      }

      if (this.getUser.description) {
        this.description = this.getUser.description;
      }
      if (this.getUser.facebook) {
        this.facebook = this.getUser.facebook;
      }
      if (this.getUser.telegram) {
        this.telegram = this.getUser.telegram;
      }

      if (this.getUser.instagram) {
        this.instagram = this.getUser.instagram;
      }
    },
    async deletePromptByUser(prompt_id) {
      Swal.fire({
        title: "Are you  sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Prompt.deletePrompt({
            address: this.getUser.address,
            prompt_id,
          })
            .then((res) => {
              if (res.data.status == "success") {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");

                this.sellingPrompt();
              } else {
                Swal.fire("Deleted!", res.data.status, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
    async getPrice() {
      await Currency.price()
        .then((res) => {
          this.price = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.caltotal();
    this.getTokenBalance();
    this.fetchBalance();
    this.fetchAssets();
    this.sellingPrompt();
    this.BuyerPrompt();
    this.getData();
    this.getPrice();
  },
  updated() {
    // this.caltota/l();
  },
};
</script>
