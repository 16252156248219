import api from "@/api/index";

export default {
  updatelisted(credentials) {
    return api().get("currency/updatelisted", credentials);
  },

  price(credentials) {
    return api().get("currency/price", credentials);
  },
};
